import debounce from "lodash.debounce";
import {Controllers} from "./controllers";
import {fetchURL} from "../utils/fetch";

function stringifyFormData(formData) {
  return JSON.stringify([...formData.entries()]);
}

/**
 * Specific autocomplete for posters
 * @param {HTMLInputElement} input
 * @return {function()}
 */
function PosterAutocompleteController(input) {
  const {form} = input;
  const targetContainer = document.getElementById("results");

  let currentValue = input.value;

  function submit() {
    fetchURL(form.action, {
      method: form.method,
      body: new FormData(form),
    })
      .then(
        (response) => response.text(),
        (error) => {
          console.warn({error});
        }
      )
      .then(update);
  }

  function update(html) {
    targetContainer.innerHTML = html;
  }

  function handleChange() {
    const {value} = input;

    if (value.length > 1 && value !== currentValue) {
      currentValue = value;
      submit();
    }
  }

  function handleClick(event) {
    const {target} = event;
    if (target.dataset.link) {
      event.preventDefault();
      fetchURL(target.href)
        .then(
          (response) => response.text(),
          (error) => {
            console.warn({error});
          }
        )
        .then(update);
    }
  }

  const changeHandler = debounce(handleChange, 300, {leading: true, maxWait: 1000});
  const clickHandler = debounce(handleClick, 300, {leading: true, maxWait: 1000});

  input.addEventListener("change", changeHandler);
  input.addEventListener("keyup", changeHandler);
  targetContainer.addEventListener("click", clickHandler);

  return () => {
    input.removeEventListener("change", changeHandler);
    input.removeEventListener("keyup", changeHandler);
    targetContainer.removeEventListener("click", clickHandler);
  };
}

Controllers.register("poster-autocomplete", PosterAutocompleteController);

function EditorController(container) {
  const preview = container.querySelector("[data-editor=preview]");
  const form = container.querySelector("[data-editor=form]");
  const loaderSrc = container.querySelector("#loader-src");
  const previewCache = {};

  if (!preview || !form || !loaderSrc) {
    console.error("Couldn't find preview, form or loader-src elements!");
    return;
  }

  // insert the loader:
  preview.insertAdjacentHTML("afterend", loaderSrc.innerText);
  const loader = preview.nextElementSibling;

  // state variables:
  let currentData = stringifyFormData(new FormData(form));
  let loading = !!preview.complete;
  let queuedUpdate;
  let lastUpdate;

  function setLoading(state) {
    loading = state;
    loader.classList[state ? "remove" : "add"]("spinner--hidden");
  }

  function setPreviewBlob(blob) {
    preview.src = URL.createObjectURL(blob);
    return blob;
  }

  function updatePreview(formData) {
    if (loading) {
      // already updating
      return Promise.reject();
    }
    setLoading(true);

    formData.set(
      "viewport",
      `${preview.parentElement.offsetWidth}x${preview.parentElement.offsetHeight}`
    );

    // new data, let's get a new preview:
    return fetch(preview.dataset.url.replace("00", "" + formData.get("design")), {
      method: "post",
      body: formData,
    })
      .then(
        (response) => {
          if (response.ok) {
            return response.blob();
          }
          return Promise.reject("An error has occurred while updating the preview.");
        },
        (error) => {
          setLoading(false);
          alert("Couldn't update the preview: " + error);
        }
      )
      .then(
        (blob) => Promise.resolve(setPreviewBlob(blob)),
        (error) => {
          setLoading(false);
          alert(error);
        }
      );
  }

  const previewClicked = debounce(formChanged, 5000, {
    leading: true,
  });
  // const updateAfterPause = debounce(updatePreview, 1000);

  // If there's a GET parameter cache=false, we don't want to cache previews:
  const cachingEnabled = !(
    new URLSearchParams(window.location.search).get("cache") === "false"
  );
  if (!cachingEnabled) {
    console.info("Preview caching disabled.");
  }

  /**
   * Called when the form changes (a lot) and ensures the preview isn't updated too much
   */
  function formChanged(event) {
    const formData = new FormData(form);
    const data = stringifyFormData(formData);
    const delay = 3000;

    function update() {
      // console.info("updating!");
      lastUpdate = Date.now();
      updatePreview(formData).then(
        (blob) => {
          lastUpdate = Date.now();
          currentData = data;
          if (cachingEnabled) {
            previewCache[data] = blob;
          }
        },
        () => {
          clearTimeout(queuedUpdate);
          queuedUpdate = setTimeout(update, delay);
        }
      );
    }

    if (data === currentData) {
      // console.info("Same request");
      // same request, don't update
      return;
    }

    const cachedBlob = previewCache[data];
    if (cachedBlob) {
      // console.info("reusing cached blob");
      setPreviewBlob(cachedBlob);
      currentData = data;
      return;
    }

    if (lastUpdate && Date.now() - lastUpdate > delay) {
      // console.info("Update was more than 3 seconds ago");
      update();
      return;
    }

    // console.info("Update in 3...");
    const target = event.target;
    if (
      target.tagName.toLocaleLowerCase() === "select" ||
      ["radio", "checkbox"].includes(target.type)
    ) {
      // console.info("updating right away");
      update();
      return;
    }
    clearTimeout(queuedUpdate);
    queuedUpdate = setTimeout(update, delay);
  }

  const imageLoaded = () => setLoading(false);

  function imageError() {
    setLoading(false);
    alert("An error occurred while loading the preview. Please try again.");
  }

  preview.addEventListener("click", previewClicked);
  preview.addEventListener("load", imageLoaded);
  preview.addEventListener("error", imageError);
  form.addEventListener("change", formChanged);

  return () => {
    preview.removeEventListener("click", previewClicked);
    preview.removeEventListener("load", imageLoaded);
    preview.removeEventListener("error", imageError);
    form.removeEventListener("change", formChanged);
  };
}

Controllers.register("editor", EditorController);

/**
 * Adds a color swatch after an input.
 * @param {HTMLInputElement} input
 */
function ColorChoiceController(input) {
  const {color, thumb = null, swatchClass = "swatch"} = input.dataset;
  if (!color) {
    return;
  }
  const label = input.nextElementSibling;
  const swatch = document.createElement("b");
  swatch.classList.add(swatchClass);
  swatch.style.backgroundColor = color;
  if (thumb) {
    swatch.style.backgroundImage = `url(${thumb})`;
  }
  const text = document.createElement("span");
  text.appendChild(label.firstChild);
  label.appendChild(text);
  label.insertBefore(swatch, label.firstChild);
}

Controllers.register("color-choice", ColorChoiceController);
