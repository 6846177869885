export const Controllers = (function ControllerRegistry() {
  const controllers = {};

  function register(name, controller) {
    controllers[name] = controller;
  }

  function applyControllers(container) {
    return container.dataset.do.split(" ").map((name) => {
      const controller = controllers[name];
      if (controller) {
        return controller(container);
      }
      console.warn(`No controller named '${name}' registered!`, container);
    });
  }

  function apply(container) {
    const controlled = [...container.querySelectorAll("[data-do]")];
    if (container.dataset.do) {
      controlled.unshift(container);
    }
    return controlled.map(applyControllers).flat();
  }

  function unapply(applied) {
    applied.forEach((cb) => cb());
  }

  return {apply, unapply, register};
})();
