const defaultHeaders = new Headers({
  "X-Requested-With": "XMLHttpRequest",
});

function toQueryString(params) {
  return Object.entries(params).map((parts) => parts.map(encodeURIComponent).join("="));
}

/**
 * Small wrapper for fetch() which sets default headers and accepts a queryParams arg.
 * @param {String} url
 * @param {RequestInit} [fetchArgs]
 * @return {Promise<Response>}
 */
export function fetchURL(url, fetchArgs) {
  fetchArgs = fetchArgs || {};
  if (fetchArgs.queryParams) {
    url += (url.includes("?") ? "&" : "?") + toQueryString(fetchArgs.queryParams);
  }

  return fetch(url, {
    headers: defaultHeaders,
    ...fetchArgs,
  });
}
