import SwipeListener from "swipe-listener";
import * as basicLightbox from "basiclightbox";

import {Controllers} from "./controllers";
import {fetchURL} from "../utils/fetch";
import {createElement} from "../utils/elements";

/**
 * Handles interaction of the main menu.
 * @param container
 */
function MenuController(container) {
  const menu = document.getElementById("menu");
  const toggle = document.querySelector("[href='#menu']");

  toggle.addEventListener("click", (event) => {
    event.preventDefault();
    menu.focus();
  });

  SwipeListener(menu);
  menu.addEventListener("swipe", ({detail}) => {
    const {directions, x} = detail;
    if (directions.left && x[1] - x[0] < -30) {
      toggle.focus();
    }
  });
}

Controllers.register("menu", MenuController);

/**
 * Submits a form when it changes.
 * @param {HTMLFormElement} form
 * @return {function(): *}
 */
function ChangeSubmitController(form) {
  function submit() {
    form.submit();
  }
  form.addEventListener("change", submit);
  return () => form.removeEventListener("change", submit);
}

Controllers.register("change-submit", ChangeSubmitController);

/**
 * Perform a fetch which replaces a container after a change event.
 * @param {HTMLElement|HTMLInputElement} element
 * @return {function(): *|undefined}
 */
function ChangeFetchController(element) {
  let {form, replace, addData} = element.dataset;

  /**
   * @type {HTMLFormElement}
   */
  const formElement = document.querySelector(form);
  const replaceElement = replace ? document.querySelector(replace) : formElement;

  if (!formElement) {
    console.warn(`change-fetch: No form element found '${form}'`);
    return;
  }

  const isSubmit = element.getAttribute("type") === "submit";
  const eventName = isSubmit ? "click" : "change";

  function handleChange(event) {
    event.preventDefault();

    const body = new FormData(formElement);
    if (addData) {
      for (const input of document.querySelectorAll(addData)) {
        body.append(input.name, input.value);
      }
    } else if (isSubmit) {
      // ensure the clicked button is in the body
      body.append(element.name, element.value);
    }

    fetchURL(formElement.action, {method: formElement.method, body: body})
      .then(
        (response) => response.text(),
        (error) => console.warn(error)
      )
      .then((html) => {
        const temp = document.createElement("div");
        // html -> dom node:
        temp.innerHTML = html.trim();
        const newElement = temp.firstElementChild;
        // replace the container
        cleanUp();
        replaceElement.parentElement.replaceChild(newElement, replaceElement);
        Controllers.apply(newElement);
      });
  }

  function cleanUp() {
    element.removeEventListener(eventName, handleChange);
  }

  element.addEventListener(eventName, handleChange);
  return cleanUp;
}

Controllers.register("change-fetch", ChangeFetchController);

/**
 * Opens a dialog on click
 * @param {HTMLElement} trigger
 * @return {function(): *}
 */
function OpenDialogController(trigger) {
  function handle(event) {
    const target = document.querySelector(trigger.getAttribute("href"));
    event.preventDefault();
    if (target) {
      target.showModal();
    }
  }
  trigger.addEventListener("click", handle);
  return () => trigger.removeEventListener("click", handle);
}

Controllers.register("open-dialog", OpenDialogController);

/**
 * Handles sticky elements
 * @param element
 */
function StickyElementController(element) {
  const {stickyTo = "bottom", stickyClass = "sticky", stickyWhen} = element.dataset;
  const classes = stickyClass.split(" ");
  const placeholder = document.createElement("div");
  placeholder.dataset.stickyPlaceholder = "";

  let sticky = false;
  let enabled = false;

  function setEnabled() {
    if (stickyWhen && window.matchMedia) {
      const newState = window.matchMedia(stickyWhen).matches;
      if (newState !== enabled) {
        enabled = newState;
        if (!enabled) {
          return cleanUp();
        }
      }
    } else {
      enabled = true;
    }
    update();
  }

  function cleanUp() {
    element.classList.remove(...classes);
    if (placeholder.parentElement) {
      placeholder.parentElement.removeChild(placeholder);
    }
  }

  function update() {
    if (!enabled) {
      return;
    }

    const ref = sticky ? placeholder : element;
    const rect = ref.getBoundingClientRect();
    const shouldBe =
      stickyTo === "bottom" ? rect.bottom > window.innerHeight : rect.top < 0;

    if (!sticky && shouldBe) {
      element.classList.add(...classes);
      placeholder.style.height = rect.height + "px";
      element.parentElement.insertBefore(placeholder, element);
    }
    if (sticky && !shouldBe) {
      cleanUp();
    }
    sticky = shouldBe;
  }

  window.addEventListener("scroll", update, {passive: true});
  window.addEventListener("resize", setEnabled, {passive: true});

  window.setTimeout(setEnabled);

  return () => {
    cleanUp();
    window.removeEventListener("scroll", update);
    window.removeEventListener("resize", setEnabled);
  };
}

Controllers.register("sticky", StickyElementController);

/**
 * Handles a tabs list
 * @param {HTMLElement} list
 * @return {function(): void}
 */
function TabsController(list) {
  const {tabActiveClass = "active", tabContentActiveClass = "active"} = list.dataset;

  let activeTab = list.querySelector("." + tabActiveClass);
  let activeContent = activeTab
    ? document.getElementById(idFromHref(activeTab.querySelector("a").href))
    : null;

  function idFromHref(href) {
    return href.split("#").pop();
  }

  function activate(event) {
    event.preventDefault();
    if (!event.target.href) {
      return;
    }

    // activate content:
    const id = idFromHref(event.target.href);
    const target = document.getElementById(id);
    if (target) {
      if (activeContent) {
        activeContent.classList.remove(tabContentActiveClass);
      }
      if (activeContent === target) {
        activeContent = null;
        activeTab && activeTab.classList.remove(tabActiveClass);
        return;
      }
      // we link to the list to clear the active tab
      if (target !== list) {
        target.classList.add(tabContentActiveClass);
        activeContent = target;
      } else {
        activeContent = null;
      }
    }

    // activate tab:
    const link = list.querySelector(`[href="#${id}"]`);
    if (activeTab) {
      activeTab.classList.remove(tabActiveClass);
    }
    if (link) {
      link.parentElement.classList.add(tabActiveClass);
    }
    activeTab = link.parentElement;
  }

  list.addEventListener("click", activate);

  return () => {
    list.removeEventListener("click", activate);
  };
}

Controllers.register("tabs", TabsController);

/**
 * Handles the initialization of different lightbox types,
 * @param {HTMLElement} element
 * @return {function(): *}
 */
function LightboxController(element) {
  const {type, attr = "href"} = element.dataset;

  function imageContent(src) {
    return createElement("img", {src});
  }

  let content;
  if (type === "image") {
    content = imageContent(element.getAttribute(attr));
  } else {
    content = createElement("p", {}, ["[no content]"]);
  }

  // create() needs a string or NodeList
  // see: https://www.npmjs.com/package/basiclightbox#createcontent-opts
  const instance = basicLightbox.create(content.outerHTML);

  function handleClick(event) {
    event.preventDefault();
    instance.show();
  }

  element.addEventListener("click", handleClick);
  return () => element.removeEventListener("click", handleClick);
}

Controllers.register("lightbox", LightboxController);

/**
 * Opens target in an overlay.
 * @param {HTMLAnchorElement} link
 */
function OverlayLinkController(link) {
  const container = document.body;
  // const menu = document.getElementById("menu");
  const state = {
    open: false,
    element: null,
    loading: false,
    appliedControllers: [],
  };

  function show(html) {
    state.element = createElement("div", {class: "overlay", tabindex: 0});
    state.element.innerHTML = html;
    state.open = true;
    container.appendChild(state.element);
    state.appliedControllers = Controllers.apply(state.element);
    state.element.addEventListener("click", handleCloseEvent);
    state.element.focus();
    setTimeout(() => state.element.classList.add("overlay--visible"));
  }

  function update(html) {
    Controllers.unapply(state.appliedControllers);
    state.element.innerHTML = html;
    state.appliedControllers = Controllers.apply(state.element);
    state.element.focus();
  }

  function hide() {
    if (!state.open) {
      return;
    }
    state.open = false;
    Controllers.unapply(state.appliedControllers);
    state.element.classList.remove("overlay--visible");
    setTimeout(() => {
      container.removeChild(state.element);
      state.element = null;
    }, 300);
  }

  function handleCloseEvent(event) {
    if (event.target === state.element || "overlayClose" in event.target.dataset) {
      event.preventDefault();
      hide();
    }
  }

  function handleClick(event) {
    event.preventDefault();
    if (state.open || state.loading) {
      return;
    }
    state.loading = true;
    // we might want a more generic / customizable loading screen later
    show(
      "<div class='editor editor--overlay'><div class='editor__panel'>Loading...</div></div>"
    );
    fetchURL(link.href)
      .then((response) => response.text())
      .then((html) => {
        state.loading = false;
        update(html);
      });
  }

  link.addEventListener("click", handleClick);
  return () => {
    link.removeEventListener("click", handleClick);
    hide();
  };
}

Controllers.register("overlay-link", OverlayLinkController);
