import {Controllers} from "./controllers";
import {getCookie, setCookie} from "../utils/cookies";

function CookieManager(cookieName = "cookies") {
  const callbacks = [];

  let state = getCookie(cookieName);

  Controllers.register("cookie-notice", CookieNoticeController);

  function addCallback(cb) {
    callbacks.push(cb);
  }

  function setState(newState) {
    state = newState;
    setCookie(cookieName, state);
    callbacks.forEach((cb) => cb(state));
  }

  function getState() {
    return state;
  }

  function CookieNoticeController(form) {
    function save() {
      const body = new FormData(form);
      fetch(form.action, {
        method: form.method,
        body,
      });
      const state = body.get("accept");
      if (state) {
        setState(state);
        hide();
      }
    }

    function handleSubmit(event) {
      event.preventDefault();
      save();
    }

    function hide() {
      form.classList.add(form.dataset.hiddenClass || "hidden");
      setTimeout(remove, parseInt(form.dataset.speed || "300"));
    }

    function remove() {
      form.parentElement.removeChild(form);
    }

    if (getState() !== null) {
      remove();
      return;
    }

    form.addEventListener("submit", handleSubmit);
    form.addEventListener("change", save);

    return () => {
      form.removeEventListener("submit", handleSubmit);
      form.removeEventListener("change", save);
    };
  }

  return {
    setState,
    getState,
    addCallback,
  };
}

export const cookieManager = new CookieManager();
