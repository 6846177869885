import {Controllers} from "./controllers";
import {fetchURL} from "../utils/fetch";

function UpdateSummaryController(trigger) {
  const {targetId} = trigger.dataset;
  const target = document.getElementById(targetId);
  if (!target) {
    console.warn(`#${targetId} not found!`);
    return;
  }
  const {fieldIds, autoTrigger, url} = target.dataset;

  const fieldElements = [
    ...document.querySelectorAll(
      "#" + fieldIds.split(",").join(",#") + ",input[name='csrfmiddlewaretoken']"
    ),
  ];
  const autoTriggerIds = autoTrigger ? autoTrigger.split(",") : [];
  const triggerElements = fieldElements.filter((e) => autoTriggerIds.includes(e.id));

  function update() {
    const formData = fieldElements.reduce((data, field) => {
      data.append(field.name, field.value);
      return data;
    }, new FormData());

    fetchURL(url, {method: "POST", body: formData})
      .then((response) => response.text())
      .then((html) => {
        target.innerHTML = html;
      });
  }

  function handleClick(event) {
    event.preventDefault();
    update();
  }

  function handleChange() {
    update();
  }

  trigger.addEventListener("click", handleClick);
  triggerElements.forEach((e) => {
    if (!e.dataset.isTrigger) {
      e.dataset.isTrigger = true;
      e.addEventListener("change", handleChange);
    }
  });

  return () => {
    trigger.removeEventListener("click", handleClick);
    triggerElements.forEach((e) => e.removeEventListener("change", handleChange));
  };
}

Controllers.register("update-order-summary", UpdateSummaryController);
