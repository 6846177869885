/**
 * Creates an element with the given attributes and children.
 * @param {String} tagName Tag name.
 * @param {Object} [attributes] Object with attributes.
 * @param {Array} [children] Array of child elements or strings.
 * @return {HTMLElement}
 */
export function createElement(tagName, attributes, children) {
  const element = document.createElement(tagName);
  attributes &&
    Object.entries(attributes).forEach(([attr, value]) =>
      element.setAttribute(attr, value)
    );
  children &&
    children.forEach((child) => {
      element.appendChild(
        typeof child === "string" ? document.createTextNode(child) : child
      );
    });
  return element;
}
