export function setCookie(name, value, days, path = "/") {
  let expires = "";
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = `${name}=${value || ""}${expires}; path=${path}`;
}

export function getCookie(name) {
  const nameEQ = name + "=";
  return (
    document.cookie.split(";").find((c) => {
      if (c.trimStart().indexOf(nameEQ) === 0) {
        return c.substring(nameEQ.length, c.length);
      }
    }) || null
  );
}

export function eraseCookie(name) {
  document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
}
