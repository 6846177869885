import dialogPolyfill from "dialog-polyfill";
import {Controllers} from "./controllers/controllers";

if (window.NodeList && !NodeList.prototype.forEach) {
  // noinspection JSValidateTypes
  NodeList.prototype.forEach = Array.prototype.forEach;
}

import {cookieManager} from "./controllers/cookies";
import "./controllers/generic";
import "./controllers/editor";
import "./controllers/checkout";

function registerDialogs(...dialogs) {
  dialogs.forEach(dialogPolyfill.registerDialog);
}

document.addEventListener("DOMContentLoaded", () => {
  document.documentElement.classList.replace("no-js", "js");
  Controllers.apply(document.body);
  registerDialogs(...document.querySelectorAll("dialog"));
});

cookieManager.addCallback((state) => {
  console.info("cookies: " + state);
});
